var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkOrder" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "订单详情",
            top: "5vh",
            visible: _vm.isshowDialog,
            width: "1160px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isshowDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _vm.form
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "wrapper",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "86%",
                        "margin-top": "-70px",
                        "margin-left": "80px",
                        cursor: "pointer",
                      },
                      on: { click: _vm.handlerOldcoed },
                    },
                    [
                      _c(
                        "el-alert",
                        {
                          attrs: {
                            closable: false,
                            title: _vm.titleTip,
                            type: "success",
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.form.bill_type_text))])]
                      ),
                    ],
                    1
                  ),
                  _c("order-status", { attrs: { status: _vm.orderStatusStr } }),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": "80px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "购货方", prop: "cust_name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.cust_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "cust_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.cust_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "boss" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.boss,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "boss",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.boss",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "dz",
                          attrs: { label: "地址", prop: "address" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "380px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "address",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库", prop: "chck" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.chck,
                              callback: function ($$v) {
                                _vm.chck =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "chck",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "送货人", prop: "deliver_name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.deliver_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "deliver_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.deliver_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员", prop: "staff_name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.staff_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "staff_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.staff_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "调拨单", prop: "allot_code" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.allot_code,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "allot_code",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.allot_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "派单", prop: "delivery_time" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.delivery_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "delivery_time",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.delivery_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "到货", prop: "arrive_time" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.arrive_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "arrive_time",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.arrive_time",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "setFilter" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "middleTable",
                      attrs: {
                        stripe: "",
                        data: _vm.middleTableData,
                        "show-summary": "",
                        "summary-method": _vm.summaryMethod,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "",
                          align: "center",
                          label: "",
                          width: "60px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                return [
                                  _c("span", [_vm._v(_vm._s($index + 1))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1065934640
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          type: "selection",
                          width: "45",
                        },
                      }),
                      _vm._l(_vm.finallyColumns, function (item, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            align: "center",
                            label: item.label,
                            sortable: item.sortable,
                            width: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              item.label == "类型"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(row.goods_type_text)),
                                        ]),
                                      ]
                                    },
                                  }
                                : item.label == "商品名称"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " + _vm._s(row[item.prop]) + " "
                                        ),
                                        row.is_abnormal == 2
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: { color: "#f60" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.is_abnormal_type_text
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  }
                                : item.label == "条码"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.currUnitCode(row)) +
                                            " "
                                        ),
                                      ]
                                    },
                                  }
                                : {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(row[item.prop])),
                                        ]),
                                      ]
                                    },
                                  },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticStyle: { "margin-top": "15px" },
                      attrs: {
                        model: _vm.form,
                        "label-width": "120px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "200px",
                                      "margin-right": "10px",
                                    },
                                    model: {
                                      value: _vm.form.remark,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "remark",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.remark",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.handleSaveRemark },
                                    },
                                    [_vm._v(" 保存备注 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户签名", prop: "" } },
                            [
                              _vm.form.sign_url
                                ? _c("el-image", {
                                    staticStyle: { height: "36px" },
                                    attrs: {
                                      "preview-src-list": [_vm.form.sign_url],
                                      src: _vm.form.sign_url,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "订购总额", prop: "total_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.total_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "total_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.total_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售金额", prop: "sale_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.sale_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "sale_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.sale_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "使用预存款",
                            prop: "deposit_amount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "110px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.deposit_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "deposit_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.deposit_amount",
                            },
                          }),
                          _vm._v(" 余额 "),
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.cust_deposit_left,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_deposit_left", $$v)
                              },
                              expression: "form.cust_deposit_left",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "还货金额", prop: "return_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.return_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "return_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.return_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "优惠后", prop: "discounted_amount" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.discounted_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "discounted_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.discounted_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div"),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退货金额",
                            prop: "refund_goods_amount",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.refund_goods_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "refund_goods_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.refund_goods_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退货方式",
                            prop: "refund_type_text",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.refund_type_text,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "refund_type_text", $$v)
                              },
                              expression: "form.refund_type_text",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "退货退款", prop: "refund_amount" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.refund_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "refund_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.refund_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "费用抵扣", prop: "support_amount" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.support_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "support_amount",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.support_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div"),
                      _c(
                        "el-form-item",
                        { attrs: { label: "应收款", prop: "receiv_money" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.receiv_money,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "receiv_money",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.receiv_money",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.style == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "结算方式",
                                prop: "account_type_text",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.account_type_text,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "account_type_text",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.account_type_text",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-divider"),
                      _vm.style == 1
                        ? _c(
                            "div",
                            { staticClass: "style1" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "销售收款:" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.form.staff_collect,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "staff_collect",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.staff_collect",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "派送收款:" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.deliver_collect,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "deliver_collect",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.deliver_collect",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "订单毛利:" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.gross_profit_amount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "gross_profit_amount",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.gross_profit_amount",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "毛利率:" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.gross_profit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "gross_profit",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.gross_profit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "欠款:" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.left_amount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "left_amount",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.left_amount",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "经办人:" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.jingbanren,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "jingbanren",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.jingbanren",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.style == 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "兑奖现金:",
                                    prop: "prize_amount",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    on: { input: _vm.rehandler },
                                    model: {
                                      value: _vm.form.prize_amount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "prize_amount",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.prize_amount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.style == 3
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "兑付现金:",
                                    prop: "cash_amount",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    on: { input: _vm.rehandler },
                                    model: {
                                      value: _vm.form.cash_amount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "cash_amount",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.cash_amount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "xTable",
                      attrs: {
                        stripe: "",
                        data: _vm.xTableData,
                        "show-summary": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "类型",
                          width: "auto",
                          prop: "type",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "收款方式",
                          width: "auto",
                          prop: "pay_type_name",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "金额",
                          width: "auto",
                          prop: "amount",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "收款人",
                          width: "auto",
                          prop: "user_name",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "时间",
                          width: "auto",
                          prop: "create_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "bottom-form" },
                    [
                      _c("div", { staticClass: "tit" }, [_vm._v("相关单据:")]),
                      _vm._l(_vm.bottomData, function (order) {
                        return _c(
                          "div",
                          { key: order.id },
                          [
                            _vm._v(" " + _vm._s(order.name) + ": "),
                            _c(
                              "span",
                              {
                                staticClass: "under-line",
                                on: {
                                  click: function ($event) {
                                    return _vm.hanldeOrderDetail(
                                      order.name,
                                      order.id
                                    )
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(order.code) + " ")]
                            ),
                            _vm._l(order.detail, function (item) {
                              return _c("span", { key: item.id }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "under-line",
                                    on: {
                                      click: function ($event) {
                                        return _vm.hanldeOrderDetail(
                                          "收款单",
                                          item.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.code) +
                                        " (" +
                                        _vm._s(item.name) +
                                        ") "
                                    ),
                                  ]
                                ),
                              ])
                            }),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: {
                        "margin-top": "5px",
                        "text-align": "right",
                      },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c("el-button", { on: { click: _vm.handlePrint } }, [
                        _vm._v("打印"),
                      ]),
                      _c("el-button", { on: { click: _vm.close } }, [
                        _vm._v("取 消"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("ruilang-display-dialog", {
            ref: "RuilangDisplayDialog",
            attrs: { bill_type: "XS", data_id: _vm.id, type: 1 },
          }),
          _c("advanceOrder", { ref: "advanceOrder" }),
          _c("diaobodan-order", { ref: "diaobodanOrder" }),
          _c("shoukuanOrder", { ref: "shoukuanOrder" }),
          _c("sale-order-detail", { ref: "checkOrder" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }